import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import apiLanding from "../../Service/AxiosLanding";
import { get_qrdetails } from "../../Service/ApiUrls";
import "../../assets/Css/landing.css";
import status from "../../assets/image/Landing/status-bar.png";
import logo from "../../assets/image/template/linkoo.png";
import play from "../../assets/image/template/play.png";
import SocialMediaLinks from "../../pages/LandingPages/CTA/SocialMediaLanding";
import round from "../../assets/image/template/round.png";
import roundTwo from "../../assets/image/template/roundTwo.png";
import AppointmentModal from "./CTA/Appointment/AppointmentLanding";
import pause from "../../assets/image/template/Pause.png";
import EventsLanding from "./CTA/Events/EventsLanding";
import FeedbacksLanding from "./CTA/Feedback/FeedbackLanding";
import ContactLanding from "./CTA/Contact/ContactLanding";
import ViewMoreLanding from "./CTA/ViewMore/ViewMoreLanding";
import LocationsLanding from "./CTA/Location/LocationLanding";
import WarrantyModal from "./CTA/Warranty/WarrantyLanding";
import DocumentDownload from "./CTA/DownloadDocument/DocumentLanding";
import GalleryLanding from "./CTA/Gallery/GalleryLanding";
import GalleryList from "./CTA/Gallery/GalleryList";
import DocumentsList from "./CTA/DownloadDocument/DocumentsList";

const Landing = () => {
  const { id } = useParams(); // This is the qrcode_value
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [qrDetails, setQrDetails] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [swap, setSwap] = useState(false);
  const [swaps, setSwaps] = useState(false);
  const [document, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New state for loader
  const [showPlayButton, setShowPlayButton] = useState(true); // State to control button visibility
  const videoRef = useRef(null); // Create a ref for the video element
  const [locationAddress, setLocationAddress] = useState(""); // New state for location address
  const [contactInfo, setContactInfo] = useState([]); // New state for contact info
  const [customLinks, setCustomLinks] = useState([]); // New state for custom links
  


  useEffect(() => {
    const hideButtonTimeout = setTimeout(() => {
      setShowPlayButton(false); // Automatically hide after 5 seconds
    }, 5000);

    const handleMouseMove = () => {
      setShowPlayButton(true); // Show the button on mouse movement
      clearTimeout(hideButtonTimeout);

      // Reset timeout to hide the button again
      setTimeout(() => {
        setShowPlayButton(false);
      }, 4000);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      clearTimeout(hideButtonTimeout);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentTime(
        now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const toggleVideoPlay = () => {
    
    const videoElement = videoRef.current; 
    if (isPlaying) {
      videoElement.pause();
    } else {
      videoElement.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const fetchQrDetails = async () => {
      try {
        const payload = {
          ip_address: "157.51.171.115",
          platform: "AndroidOS",
        };

        const response = await apiLanding.post(get_qrdetails, {
          qrcode_value: id,
          ...payload,
        });

        if (
          response.data &&
          response.data.data &&
          response.data.data.qr_details
        ) {
          const qrDetailsData = response.data.data.qr_details;
          setQrDetails(qrDetailsData);

          // Extract and set the video URL
          if (qrDetailsData.video_url) {
            setVideoUrl(
              qrDetailsData.upload_type === "upload"
                ? `https://linkoo.s3.amazonaws.com//${qrDetailsData.video_url}`
                : qrDetailsData.video_url
            );
          }

          // Set social media links
          if (response.data.data?.social_media_links) {
            setSocialMediaLinks(response.data.data.social_media_links);
          }

          if (response.data.data?.gallery_images?.gallery_images) {
            setGallery(response.data.data.gallery_images.gallery_images);
          }
          if (response.data.data?.documents?.document_images) {
            setDocuments(
              response.data.data.documents.document_images
            );
          }
          // Set location address
          if (response.data.data?.locations?.location_address) {
            setLocationAddress(response.data.data.locations.location_address);
          }

          // Set contact info
          if (response.data.data?.contact_info) {
            setContactInfo(response.data.data.contact_info);
          }

          if (response.data.data?.custom_links) {
            setCustomLinks(response.data.data.custom_links);
          }

        } else {
          console.error("Invalid API response structure");
        }
      } catch (error) {
        console.error("Error fetching QR details:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };

    fetchQrDetails();
  }, [id]);

  // useEffect(() => {
  //     const fetchQrDetails = async () => {
  //         try {
  //             const payload = {
  //                 ip_address: "157.51.171.115",
  //                 platform: "AndroidOS",
  //             };

  //             const response = await apiLanding.post(get_qrdetails, {
  //                 qrcode_value: id,
  //                 ...payload,
  //             });

  //             if (response.data && response.data.data && response.data.data.qr_details) {
  //                 const qrDetailsData = response.data.data.qr_details;
  //                 setQrDetails(qrDetailsData);

  //                 // Extract and set the video URL
  //                 if (response.data.data.qr_details.video_url) {
  //                     if(response.data.data.qr_details.upload_type === "upload"){
  //                     setVideoUrl(`https://linkoo.s3.amazonaws.com//${response.data.data.qr_details.video_url}`);}
  //                     else{
  //                     setVideoUrl(`${response.data.data.qr_details.video_url}`);}

  //                 }
  //                 // console.log("video url", response.data.data.qr_details);

  //                 // Set social media links
  //                 if (response.data.data.social_media_links) {
  //                     setSocialMediaLinks(response.data.data.social_media_links);
  //                 }
  //                 console.log("social", response.data.data.social_media_links)

  //             } else {
  //                 console.error("Invalid API response structure");
  //             }
  //         } catch (error) {
  //             console.error("Error fetching QR details:", error);
  //         } finally {
  //             setIsLoading(false); // Set loading to false after fetching data
  //         }
  //     };

  //     fetchQrDetails();
  // }, [id]);

  console.log("videoUrl", videoUrl);
  console.log("details", qrDetails);
  if (swap) {
    return <GalleryList setSwap={setSwap} gallery={gallery} />;
  }

  if (swaps) {
    return <DocumentsList setSwaps={setSwaps} document={document} />;
  }
  return (
    <div className="container-landing">
      <style>
        {`
                    .loader-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100vh;
                        background-color: #fff;
                    }
                    
                    .loader {
                        border: 8px solid #f3f3f3;
                        border-top: 8px solid #ff69b4;
                        border-right: 8px solid #000000;
                        border-radius: 50%;
                        width: 60px;
                        height: 60px;
                        animation: spin 1.2s linear infinite;
                    }
                    
                    @keyframes spin {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                         .play-btn {
      display: ${showPlayButton ? "block" : "none"};
    }
                `}
      </style>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {/* <div className="small-image-row">
                        <img src={round} alt="Image 1" className="rounded-image" />
                        <img src={roundTwo} alt="Image 2" className="rounded-image" />
                        <img src={round} alt="Image 3" className="rounded-image" />
                        <img src={round} alt="Image 4" className="rounded-image" />
                    </div> */}

          <div className="video-banner">
            {videoUrl ? (
              <video
                id="backgroundVideo"
                ref={videoRef} 
                autoPlay
                muted
                loop
                onLoadedData={() => setIsLoading(false)} // Remove loader once video loads
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <p>Loading video...</p>
            )}
            <div className="overlay top-overlay"></div>
            <div className="overlay bottom-overlay"></div>
            <div className="video-content">
              <div className="header-content">
                <h1 id="header-content">
                  {qrDetails?.header_content || "Your Header Content"}
                </h1>
              </div>
              <div className="play-btn" onClick={toggleVideoPlay}>
                <img
                  src={isPlaying ? pause : play}
                  alt={isPlaying ? "Pause" : "Play"}
                />
              </div>
              <div className="bottom-content">
                {qrDetails?.cta_category_id === 1 && (
                  <SocialMediaLinks
                    links={socialMediaLinks}
                    id={qrDetails?.id}
                  />
                )}
                {qrDetails?.cta_category_id === 2 && (
                  <AppointmentModal id={qrDetails?.id} />
                )}
                {qrDetails?.cta_category_id === 6 && (
                  <EventsLanding id={qrDetails?.id} />
                )}
                {qrDetails?.cta_category_id === 7 && (
                  <FeedbacksLanding id={qrDetails?.id} />
                )}
                {qrDetails?.cta_category_id === 8 && (
                  <ContactLanding id={qrDetails?.id} contactInfo={contactInfo} />
                )}
                {qrDetails?.cta_category_id === 3 && (
                  <ViewMoreLanding id={qrDetails?.id} customLinks={customLinks}/>
                )}
                {qrDetails?.cta_category_id === 5 && (
                  <LocationsLanding id={qrDetails?.id}  locationAddress={locationAddress} />
                )}
                {qrDetails?.cta_category_id === 14 && (
                  <WarrantyModal id={qrDetails?.id} />
                )}
                {qrDetails?.cta_category_id === 13 && (
                  <DocumentDownload
                    setSwaps={setSwaps}
                    id={qrDetails?.id}
                    document={document}
                  />
                )}
                {qrDetails?.cta_category_id === 15 && (
                  <GalleryLanding
                    setSwap={setSwap}
                    gallery={gallery}
                    id={qrDetails?.id}
                  />
                )}
              </div>
            </div>
          </div>

          <footer>
            <div className="footer-powered">
              <span>Powered by</span>
              <img src={logo} alt="linkoo" />
            </div>
          </footer>
        </>
      )}
    </div>
  );
};

export default Landing;
